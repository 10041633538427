a {
  text-decoration: none;
}

/* React Verification Code Input CSS */
.code-input {
  margin-bottom: 10px;
  margin-top: 0px;
}

.code-input input {
  font-weight: bold !important;
  font-family: "Inter" !important;
  color: #068ecf !important;
  border: none !important;
  border-bottom: 2px solid #068ecf !important;
  border-radius: 0 !important;
}

.code-input div {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

@media (min-width: 1401px) {
  .code-input {
    width: 535px !important;
    margin-bottom: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .code-input {
    width: 400px !important;
  }
}

@media (max-width: 1023px) {
  .code-input {
    width: 250px !important;
  }
  .code-input input {
    width: 45px !important;
  }
}

/* React Quill Description Editor CSS */
.ql-editor {
  font-family: "Inter" !important;
  font-size: 1rem;
  min-height: 200px;
}

.ql-editor.ql-blank::before {
  font-size: 1rem;
  font-style: normal !important;
}

.ql-container {
  word-break: break-word;
}

#terms-conditions ul {
  list-style-type: none;
}

#terms-conditions li::before {
  content: "\2022";
  color: #0572a6;
  margin-left: -1.1em;
  margin-right: 1em;
}

/* React Simple Image Viewer CSS */
#ReactSimpleImageViewer {
  z-index: 9999;
}
